<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container" v-if="applications">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['UserManagementMain', 'RequestRegistration']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.request_registration') }}
										</div>
										<div class="content__title--subtext">
											{{ $t('titles.subject_fish_farm') }} ({{ applications.first_name }}
											{{ applications.last_name }} {{ applications.middle_name }})
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">{{ $t('headers.user_name') }}</div>
									</div>
									<div class="form__line--right">
										<div class="row">
											<div class="col-xl-5">
												<div class="form__line--input">
													<input
														type="text"
														class="input-linck"
														:value="applications.first_name"
														disabled
														:placeholder="$t('headers.user_name')"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">{{ $t('headers.user_surname') }}</div>
									</div>
									<div class="form__line--right">
										<div class="row">
											<div class="col-xl-5">
												<div class="form__line--input">
													<input
														type="text"
														class="input-linck"
														:value="applications.last_name"
														disabled
														:placeholder="$t('headers.user_surname')"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">{{ $t('headers.user_patronymic') }}</div>
									</div>
									<div class="form__line--right">
										<div class="row">
											<div class="col-xl-5">
												<div class="form__line--input">
													<input
														type="text"
														class="input-linck"
														:value="applications.middle_name"
														disabled
														:placeholder="$t('headers.user_patronymic')"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">{{ $t('headers.name_company') }}</div>
									</div>
									<div class="form__line--right">
										<div class="row">
											<div class="col-xl-5">
												<div class="form__line--input">
													<input
														type="text"
														class="input-linck"
														:value="applications.name_company"
														disabled
														:placeholder="$t('headers.name_company')"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">{{ $t('headers.iin') }}</div>
									</div>
									<div class="form__line--right">
										<div class="row">
											<div class="col-xl-5">
												<div class="form__line--input">
													<input
														type="text"
														class="input-linck"
														:value="applications.iin_bin"
														disabled
														:placeholder="$t('headers.iin')"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">{{ $t('headers.email') }}</div>
									</div>
									<div class="form__line--right">
										<div class="row">
											<div class="col-xl-5">
												<div class="form__line--input">
													<input
														type="text"
														class="input-linck"
														:value="applications.email"
														disabled
														:placeholder="$t('headers.email')"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form__line">
									<div class="form__line--left">
										<div class="form__line--title">{{ $t('headers.file') }}</div>
									</div>
									<div class="form__line--right">
										<div class="row">
											<div class="col-xl-5">
												<div class="form__line--input form__block--input">
													<a class="form__line--file form__line--link" @click="downloadApp">
														<div class="form__file">
															<div class="form__file--icon">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 16.2A4.5 4.5 0 0017.5 8h-1.8A7 7 0 104 14.9M12 12v9M8 17l4 4 4-4" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
															</div>
															<div class="form__file--text">{{ $t('button.download') }}</div>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="form__line form__line--comment" v-if="applications.comment">
									<div class="form__line--left">
										<div class="form__line--title">Причина отказа</div>
									</div>
									<div class="form__line--right">
										<div class="col-xl-12">
											{{ applications.comment }}
										</div>
									</div>
								</div>

								<div class="form__line">
									<div class="form__line--right">
										<div class="input__row">
											<button
												class="input-btn form__line--btn"
												v-if="applications.status == 'new'"
												@click="activeElement(applications.id)"
											>
												{{ $t('labels.approve') }}
												<img src="../../../assets/img/icon-success-w.svg" />
											</button>
											<button
												class="input-btn form__line-error form__line--btn"
												v-if="applications.status == 'new'"
												@click="$modal.show('modal_delete')"
											>
												{{ $t('button.deactivate')
												}}<img src="../../../assets/img/icon-close-w.svg" />
											</button>
										</div>
									</div>
								</div>

								<div
									class="form__block--line form__messages"
									v-if="message.text"
									:class="{
										'form__messages--error': message.status != 200,
										'form__messages--success': message.status == 200,
									}"
								>
									<label class="form__block--input">
										<span class="form__block--title">
											{{ message.text }}
										</span>
									</label>
								</div>

								<modal
									class="modal__block modal__block--middle modal__block--delete"
									name="modal_delete"
									:width="550"
									:min-width="550"
									height="auto"
								>
									<div class="modal__block--wrapper">
										<div class="modal__block--close" @click="$modal.hide('modal_delete')">
											<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</div>
										<div class="modal__wrapper">
											<div class="modal__header">
												<div class="modal__title">
													{{
														$t('messages.are_you_sure_to_reject_user', [
															applications.first_name +
																' ' +
																applications.last_name +
																' ' +
																applications.middle_name,
														])
													}}<br />
												</div>
											</div>

											<form method="POST" @submit.prevent="blockElement">
												<div class="modal__content">
													<div class="modal__form">
														<div class="form__line">
															<div class="form__line--right">
																<div
																	class="form__line--input"
																	:class="[
																		errorMessage.messages && errorMessage.messages.comment
																			? 'input--error'
																			: '',
																	]"
																>
																	<textarea
																		class="input-linck"
																		required=""
																		name="comment"
																		:placeholder="$t('labels.reject_reason')"
																	></textarea>
																</div>
																<div
																	class="input-required"
																	v-if="errorMessage.messages && errorMessage.messages.comment"
																>
																	<p
																		v-for="(massage, massageInd) in errorMessage.messages.comment"
																		:key="massageInd"
																	>
																		{{ massage }}
																	</p>
																</div>
															</div>
														</div>

														<div class="block__row">
															<div class="block__row--left">
																<button class="block__item">
																	<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																	{{ $t('button.deactivate') }}
																</button>
															</div>
															<div class="block__row--right">
																<a
																	class="block__item block__item--blue"
																	@click="$modal.hide('modal_delete')"
																>
																	{{ $t('button.cancel') }}
																</a>
															</div>
														</div>
														<div
															class="form__block--line form__messages"
															v-if="message.status"
															:class="{
																'form__messages--error': message.status != 200,
																'form__messages--success': message.status == 200,
															}"
														>
															<label class="form__block--input">
																<span class="form__block--title">
																	{{ message.text }}
																</span>
															</label>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</modal>
							</div>
							<div
								v-if="errorMessage && errorMessage.text"
								class="form__preloader--text form__block--line form__messages form__messages--error"
							>
								<label class="form__block--input">
									<span class="form__block--title">
										{{ errorMessage.text }}
									</span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api, urlApi } from '@/boot/axios'

export default {
	data() {
		return {
			urlApi: urlApi,
			applications: null,
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
				texts: null,
			},

			// roleModel: [],
			// roleList: null,
		}
	},
	methods: {
		downloadApp() {
			api
				.get('https://api.efish.kz/api/v1/user/register/' + this.$route.params.id + '/file', {
					responseType: 'blob',
				})
				.then((res) => {
					const url = window.URL.createObjectURL(new Blob([res.data]))
					const a = document.createElement('a')
					a.style.display = 'none'
					a.href = url
					a.download = this.applications.document.split('/').slice(-1)
					document.body.appendChild(a)
					a.click()
					window.URL.revokeObjectURL(url)
				})
		},
		activeElement(id) {
			const formData = new FormData()
			formData.append('role_id', 3)
			api
				.post('user/register/' + id + '/accept', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message
						this.applications.status = null
						this.$router.push({ path: '/' + this.$i18n.locale + '/account/request_registration' })
					}
				})
				.catch((error) => {
					if (error.response == 'undefined') {
						this.applications.status = null
					}
					if (error.response && error?.response?.status == 200) {
						this.message.status = 200
						this.message.text = error?.response?.data?.message
						this.$router.push({ path: '/' + this.$i18n.locale + '/account/request_registration' })
					}
					if (error.response && error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
					}
					if (error.response && error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
				})
		},
		blockElement(evt) {
			const formData = new FormData(evt.target)
			api
				.post('user/register/' + this.applications.id + '/deny', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.$modal.hide('modal_delete')
						this.$router.push({ path: '/' + this.$i18n.locale + '/account/request_registration' })
					}
				})
		},

		apiGetUser() {
			api
				.get('user/register/' + this.$route.params.id)
				.then((response) => {
					if (response.data) {
						this.applications = response.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 404) {
						this.errorMessage.status = 404
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
		/*apiGetUserList(){
      api.get('user/role/list').then(response => {
        if (response.data) {
          this.roleList = response.data;
        }
      }).catch((error) => {
        if (error?.response?.status == 500) {
          this.errorMessage.status = 500;
          this.errorMessage.text = this.$t('system_message.500');
        }
        if (error?.response?.status == 401) {
          this.errorMessage.status = 401;
          this.errorMessage.text = error.response.data.error_message;
          this.$router.push('/'+this.$i18n.locale+'/login');
        }
        if (error?.response?.status == 422) {
          this.errorMessage.status = 422;
          this.errorMessage.text = error.response.data.error_message;
        }
      });
    }*/
	},
	beforeMount() {
		this.apiGetUser()
		// this.apiGetUserList();
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>
